html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  font-family: 'Russo One', sans-serif;
  height: 100vh;
  margin: 0;
}

button { font-size: 1.6rem; }

.full-width { width: 100%; }

#root {  height: 100%; }

.appContainer {
  align-items: flex-start;
  width: 100%;
  height: 100%;
  top: 0; left: 0; 
  display: -webkit-flex; 
  overflow: hidden;
}

.imageContainer{
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
}

.image{
  display: flex;
  width: 100vw;
  height: 100vh;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ MENÜ +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.menuContainer{
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 2;
  padding-top: 6rem;
  padding-left: 1rem;
}

.menuHomeContainer{
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 2;
  padding-top: 26rem;
  padding-left: 2rem;
}

.NavLink{
  display:flex;
  color: #fff;
  font-size: 2.4rem;
  line-height: 6rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}

.NavLink:hover{
  display:flex;
  color: #742c11;
  font-size: 2.5rem;
  line-height: 6rem;
  text-decoration: none;
  transition: 0.2s;
}

.menuUser{
  display:flex;
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
  text-decoration: none;
  cursor: pointer
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ LOGIN/REGISTER +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.flex {
  display: -webkit-flex; 
}

.loginForm {
  display: -webkit-flex; 
  width: 20%;
  position: absolute;
  z-index: 2;
  right: 20%;
  top: 9%;
  
}

.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}
.button.primary {
  background-color: #07827b;
}

.button.primary:hover {
  background-color: #3e9893;
}
.button.secondary {
  background-color: #f0f0f0;
  color: #315856;
  text-decoration: none;
}
.button.secondary:hover {
  background-color: #f0f0f0;
  color: #3e9893;
  text-decoration: none;
}

.text-center {
  text-align: center;
}


/* forms */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
}


.form-container {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#d1e2e1, #8faaa8);
  margin-left: 30%;
  width: 40rem;
  padding: 2rem;
  border: 0.1rem #c0c0c0 solid;
  box-shadow: 5px 5px 10px #bfbfbf;
  list-style-type: none;
  font-size: 2rem;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
input {
  padding: 1rem;
  border: 0.1rem #c0c0c0 solid;
  border-radius: 0.5rem;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ ADMIN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.admin {
  margin-top: 2rem;
  margin-left: 40rem;
  display: flex; 
  flex-direction: row;
  position: absolute;
  z-index: 2;

}

.adminTable {
  display: flex; 
  flex-direction: column;
  z-index: 2;

}

.adminRow{
  display: flex;
  flex-direction: row; justify-content: flex-start; align-items: center; width: 66vw;
  background-color: 	 #d9d9d9;
  margin: 1rem;
  padding: 1rem;
  border-collapse: collapse;
  border-spacing: 1rem;
}

#adminUser{
  width: 30vw;
  font-size: 1.6rem;

}

#adminRole{
  width: 20vw;
  font-size: 1.6rem;
  
}

.buttonVisible{
  opacity: 1;
}

.buttonInvisible{
  opacity: 0;
}

.pdf{
  display:flex;
  flex-direction: column;
}

.pdfUpload {
  display: flex; 
  flex-direction: column;
  padding: 2rem;
  border: 0.2rem, solid;
  
  border-radius: 2rem;
  z-index: 2;

}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ UPLOADSCREEN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.uploadContainer {
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: -webkit-flex; 
  overflow: hidden;
}

.searchContainer {
  align-items: flex-start;
  flex-direction: row;
  width: 65%;
  height: 100%;
  margin-top: 4rem; 
  margin-left: 45rem; 
  display: -webkit-flex; 
  overflow: hidden;
}

.search {
  margin-top: 25rem;
  margin-left: 50rem;
  display: flex; 
  height: 70%;
  width: 95rem;
  overflow: auto;
  flex-direction: column;
  position: absolute;
  z-index: 2;

}

.searchDropdown {
  margin-top: 8rem;
  margin-left: 35rem;
  width: 56rem;

  position: absolute;
  z-index: 2;

}

.custom-file-input { width: 56rem; }

.input-group-text{ cursor: pointer; }

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ POPUP +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.popupContainer{
  display: flex;
  position: absolute;
  width: 80%;
  height: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 10%;
  margin-bottom: 20%;
  z-index: 1111;
  overflow: hidden;


}

.popup{
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 40vh;
  padding: 10rem;
  box-shadow: 5px 5px 10px #808080;
  background-color:  #d9d9d9;
  z-index: 1111;
  overflow: hidden;
  text-align: center;

}

#popupHide{ margin-left: 160rem; }

#popupShow{ margin-left: 10rem; }

.popupButton {
  border-radius: 2px;
  padding: 8px 14px;
  width: 10rem;
  margin-top: 6rem;
  background-color: #f0f0f0;
  color: #315856;
  transition-duration: 0.4s;
}

.popupButton:hover {
  background-color: #f0f0f0;
  color: #3e9893;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ CONTENTSCREEN +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

.content {
  margin-top: 6rem;
  margin-left: 50rem;
  display: flex; 
  height: 90%;
  width: 95rem;
  overflow: auto;
  flex-direction: column;
  position: absolute;
  z-index: 2;

}

.contentTable {
  flex-direction: column; justify-content: flex-start; align-items: flex-start; width: 70vw; height: 55vh;
  margin-left: 2rem;
  z-index: 2;

}

.contentRow{
  display: flex;
  flex-direction: row; justify-content: flex-start; align-items: center; width: 70vw; height: 5vh;
  padding: 1rem;
  border-collapse: collapse;
}

#contentPic{ width: 3vw; }

#contentPicture{
  width: 2vw;

}

#contentTitle{ 
  width: 70vw; 
  color: #808080;
}

#contentTitle:hover{ color: #742c11; }
